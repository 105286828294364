import { makeStyles, Theme } from "@material-ui/core/styles";
import MuiTooltip, { TooltipClassKey, TooltipProps as MuiTooltipProps } from "@material-ui/core/Tooltip";
import { ClassNameMap } from "@material-ui/styles";
import clsx from "clsx";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 250,
    padding: theme.spacing(0.5, 1),
  },
  tooltipDisabled: {
    display: "none",
  },
}));

export type TooltipJSSClassKey = TooltipClassKey | keyof ReturnType<typeof useStyles>;

export type TooltipProps = MuiTooltipProps & {
  className?: string;
  classes?: Partial<ClassNameMap<TooltipJSSClassKey>>;
  disabled?: boolean;
  disableTooltip?: boolean;
};

export const Tooltip: FC<TooltipProps> = ({ className, classes: extClasses, children, disabled, ...rest }) => {
  const classes = useStyles({ classes: extClasses });

  const { tooltipDisabled: tooltipDisabledCn, tooltip: tooltipCn, ...muiTooltipClasses } = classes;

  return (
    <MuiTooltip
      className={className}
      classes={{
        ...muiTooltipClasses,
        tooltip: clsx(classes.tooltip, {
          [classes.tooltipDisabled]: disabled,
        }),
      }}
      arrow
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
